import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Pricing from "../components/Pricing";
import CTA from "../components/CTA";
import Explainer from "../components/Explainer";
import SEO from "../components/SEO";
import BaseLayout from "../components/layouts/BaseLayout";
import Features from "../components/Features";
import PosterDownload from "../components/PosterDownload";

const IndexPage = () => {
  const { kanbanDone, screenshotMerkzettel } = useStaticQuery(
    graphql`
      query {
        kanbanDone: file(relativePath: { eq: "kanban-done.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
        screenshotMerkzettel: file(
          relativePath: { eq: "screenshot-merkzettel.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <BaseLayout isHeroVisible={true}>
      <SEO />
      <Explainer
        title="Merkzettel digital archivieren statt entsorgen"
        paragraphs={[
          "Solange ein Merkzettel aktiv auf dem Board bearbeitet wird haben wir eine super Übersicht. Doch was passiert eigentlich, wenn das Arbeitspaket abgeschlossen ist? Richtig, der Zettel landet irgendeinmal im Mülleimer.",
          ,
          "Mit dem Merkzettel werden so aber auch alle gemeinsam am Board abgehaltenen Gespräche und getroffene Entscheidungen entsorgt. Und genau hier kommt Shotdok ins Spiel!",
          "Mit Shotdok werden wichtige Entscheidungen spielend leicht und direkt am Scrum- oder Kanban-Board für immer festgehalten. Jedes Teammitglied kann mit dem eigenen Smartphone oder Tablet Einträge hinzufügen und verwalten. Einfach ein Foto oder Video des Merkzettels aufnehmen und optional mit Sprachnotiz oder Texteingabe ergänzen.",
        ]}
        image={kanbanDone.childImageSharp.gatsbyImageData}
        imageAlt="Ein Mann entfernt ein Merkzettel aus der Done-Spalte des Kanban Boards"
        isImageLeft={true}
      />
      <Explainer
        title="Entscheidungen Jahre später nachvollziehen"
        paragraphs={[
          "Die mit Shotdok digital archivierten Merkzettel werden strukturiert in Teams und Themenbereiche gespeichert. Über die Suche wird der gewünschte Eintrag und Entscheid ganz einfach und schnell gefunden. So können ehemalige Entscheide auch Jahre später nachvollzogen werden.",
          "Shotdok ist dabei bewusst so aufgebaut, dass sich die App ideal in den agilen Prozess einfügt: Durch die minimale Struktur und optimierte Bedienung bremst sie den schnellen Arbeitsablauf nicht.",
          "In diesem Screenshot der App sieht man verschiedene Arbeitspakete, die mit Shotdok erfasst wurden. Die dazu getroffenen Entscheidungen wurden jeweils per Sprachnotiz oder Videoaufnahme festgehalten.",
        ]}
        image={screenshotMerkzettel.childImageSharp.gatsbyImageData}
        imageAlt="Screenshot der Shotdok App"
        isImageLeft={false}
      />
      <Features />
      <Pricing />
      <CTA />
      <PosterDownload />
    </BaseLayout>
  );
};

export default IndexPage;
